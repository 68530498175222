import React, { Fragment } from "react";
import {
  Layout,
  Button,
  Modal,
  Icon,
  notification,
  Card,
  Spin,
  Tooltip,
} from "antd";
const { confirm } = Modal;
const { Header, Content, Footer, Sider } = Layout;
import { reactLocalStorage } from "reactjs-localstorage";
/*
import MicrophoneIcon from "mdi-react/MicrophoneIcon";
import MicrophoneOffIcon from "mdi-react/MicrophoneOffIcon";
import ChatIcon from "mdi-react/ChatIcon";
import HangupIcon from "mdi-react/PhoneHangupIcon";
import TelevisionIcon from "mdi-react/TelevisionIcon";
import TelevisionOffIcon from "mdi-react/TelevisionOffIcon";
import VideoIcon from "mdi-react/VideoIcon";
import VideocamOffIcon from "mdi-react/VideocamOffIcon";
*/
import * as Icons from "./icons/icons";
import { IconButton } from "./icons/icons";
import MediaSettings from "./settings";
import ToolShare from "./ToolShare";
import ChatFeed from "./chat/index";
import Message from "./chat/message";
import Draggable from "react-draggable";

import { CircularProgress } from "@material-ui/core";

import __ from "./util/translate";
import "../styles/css/app.scss";
//import "../styles/css/themes/medakte.scss";

import LoginForm from "./LoginForm";
import Conference from "./Conference";
import { Client, Stream } from "ion-sdk-js";

import app_config, { fromInlineConfig } from "./config";
import { isImageUrl } from "antd/lib/upload/utils";

const home_url = app_config.home_link;
const logo = app_config.logo;

notification.config({
  placement: "topLeft",
  top: 90,
  duration: 3,
  rtl: true,
});

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      login: false,
      loading: false,
      localAudioEnabled: true,
      localVideoEnabled: true,
      screenSharingEnabled: false,
      simpleUserMode: true,
      minimizeMode: false,
      collapsed: false,
      visible: true,
      //visible: false,
      //collapsed: true,
      isFullScreen: document.fullscreenElement ? true : false,
      vidFit: false,
      loginInfo: {},
      messages: [],
    };

    this._settings = {
      selectedAudioDevice: "",
      selectedVideoDevice: "",
      resolution: "hd",
      bandwidth: 1024,
      codec: "vp8",
      isDevMode: false,
      simpleUserMode: true,
    };

    let settings = reactLocalStorage.getObject("settings");
    if (settings.codec !== undefined) {
      this._settings = settings;
    }
    console.log("settings", this._settings);
  }

  componentWillMount = () => {
    document.addEventListener("fullscreenchange", this.fullScreenChanged);
    document.addEventListener("webkitfullscreenchange", this.fullScreenChanged);
    document.addEventListener("mozfullscreenchange", this.fullScreenChanged);
    document.addEventListener("MSFullscreenChange", this.fullScreenChanged);
  };

  fullScreenChanged = () => {
    this.setState({ isFullScreen: document.fullscreenElement ? true : false });
  };

  _userMediaFoundCallback = () => {
    //alert('now')
    this.forceUpdate();
  };

  _cleanUp = async () => {
    if (
      window.videochat &&
      window.videochat.callbacks &&
      window.videochat.callbacks.onMeetingLeave
    ) {
      window.videochat.callbacks.onMeetingLeave({
        roomId: this.state.loginInfo.roomId,
        displayName: this.state.loginInfo.displayName,
      });
    }
    await this.conference.cleanUp();
    await this.client.leave();
  };

  _notification = (message, description) => {
    if (this._settings.isDevMode) {
      notification.info({
        message: message,
        description: description,
        //placement: 'bottomRight',
      });
    }
  };

  _handleJoin = async (values) => {
    this.setState({ loading: true });

    let port = fromInlineConfig("port", 8443);
    let host = fromInlineConfig("host", window.location.hostname);
    let protocol = fromInlineConfig("protocol", "wss");
    let url = protocol + "://" + host;
    //for dev by scripts
    if (
      process.env.NODE_ENV == "development" &&
      host === window.location.hostname
    ) {
      const proto = this._settings.isDevMode ? "ws" : "wss";
      console.log(proto, "proto");
      url = proto + "://" + host + ":" + port;
    }

    console.log("WS url is:" + url);
    let client = new Client({ url: url });

    window.onunload = async () => {
      await this._cleanUp();
    };

    client.on("peer-join", (id, info) => {
      this._notification("Peer Join", "peer => " + info.name + ", join!");
    });

    client.on("peer-leave", (id) => {
      this._notification("Peer Leave", "peer => " + id + ", leave!");
    });

    client.on("transport-open", () => {
      console.log("transport open!");
      this._handleTransportOpen(values);
    });

    client.on("transport-closed", () => {
      console.log("transport closed!");
    });

    client.on("stream-add", (id, info) => {
      console.log("stream-add %s,%s!", id, info);
      this._notification(
        "Stream Add",
        "id => " + id + ", name => " + info.name
      );
    });

    client.on("stream-remove", (stream) => {
      console.log("stream-remove %s,%", stream.id);
      this._notification("Stream Remove", "id => " + stream.id);
    });

    client.on("broadcast", (mid, info) => {
      console.log("broadcast %s,%s!", mid, info);
      this._onMessageReceived(info);
    });

    this.client = client;

    if (
      window.videochat &&
      window.videochat.callbacks &&
      window.videochat.callbacks.onMeetingJoin
    ) {
      try {
        window.videochat.callbacks.onMeetingJoin({
          roomId: values.roomId,
          displayName: values.displayName,
        });
      } catch (e) {
        this._notification(
          __("errors.connection", "Verbindungsfehler"),
          __(
            "errors..connection_message",
            "Fehler beim Aufbau der Verbindung: "+e.message
          )
        );
      }
    }
  };

  _handleTransportOpen = async (values) => {
    reactLocalStorage.remove("loginInfo");
    reactLocalStorage.setObject("loginInfo", values);
    await this.client.join(values.roomId, { name: values.displayName });
    this.setState({
      login: true,
      loading: false,
      loginInfo: values,
      localVideoEnabled: !values.audioOnly,
    });

    this._notification(
      __("conference.connected_title", "Verbunden"),
      __(
        "conference.connected_message",
        "Sie wurden erfolgeich verbunden - {0}.",
        values.roomId
      )
    );
    await this.conference.handleLocalStream(true);
  };

  _handleLeave = async () => {
    let client = this.client;
    let this2 = this;
    confirm({
      title: __("conference.leave_room_title", "Videocall beenden"),
      content: __(
        "conference.leave_room_message",
        "Wollen Sie wirklich den Videochat beenden?"
      ),
      okText: __("ok", "OK"),
      cancelText: __("cancel", "Abbrechen"),
      async onOk() {
        console.log("OK");
        await this2._cleanUp();
        this2.setState({ login: false });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  _handleAudioTrackEnabled = (enabled) => {
    this.setState({
      localAudioEnabled: enabled,
    });
    this.conference.muteMediaTrack("audio", enabled);
  };

  _handleVideoTrackEnabled = (enabled) => {
    this.setState({
      localVideoEnabled: enabled,
    });
    this.conference.muteMediaTrack("video", enabled);
  };

  _handleScreenSharing = (enabled) => {
    this.setState({
      screenSharingEnabled: enabled,
    });
    this.conference.handleScreenSharing(enabled);
  };

  _onRef = (ref) => {
    this.conference = ref;
  };

  _openOrCloseWindow = (visible) => {
    this.setState({
      visible: visible,
    });
  };

  _openOrCloseLeftContainer = (collapsed) => {
    this.setState({
      collapsed: collapsed,
    });
  };

  _onVidFitClickHandler = () => {
    this.setState({
      vidFit: !this.state.vidFit,
    });
  };

  _onMinimizeClickHandler = () => {
    this.setState({
      minimizeMode: !this.state.minimizeMode,
    });
  };

  _onFullScreenClickHandler = () => {
    let docElm = document.documentElement;

    if (this._fullscreenState()) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      //this.setState({ isFullScreen: false });
    } else {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }
      //FireFox
      else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }
      //Chrome等
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
      //IE11
      else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }

      //this.setState({ isFullScreen: true });
    }
  };

  _fullscreenState = () => {
    return (
      document.fullscreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      false
    );
  };

  _onMediaSettingsChanged = (
    simpleUserMode,
    selectedAudioDevice,
    selectedVideoDevice,
    resolution,
    bandwidth,
    codec,
    isDevMode
  ) => {
    this._settings = {
      simpleUserMode,
      selectedAudioDevice,
      selectedVideoDevice,
      resolution,
      bandwidth,
      codec,
      isDevMode,
    };
    reactLocalStorage.setObject("settings", this._settings);
  };

  _onMessageReceived = (data) => {
    let messages = this.state.messages;
    let uid = 1;
    messages.push(
      new Message({ id: uid, message: data.msg, senderName: data.senderName })
    );
    this.setState({ messages });
  };

  _onSendMessage = (data) => {
    var info = {
      senderName: this.state.loginInfo.displayName,
      msg: data,
    };
    this.client.broadcast(info);
    if (
      window.videochat &&
      window.videochat.callbacks &&
      window.videochat.callbacks.onMessageSend
    ) {
      window.videochat.callbacks.onMessageSend({
        roomId: this.state.loginInfo.roomId,
        displayName: this.state.loginInfo.displayName,
        message: data,
      });
    }
    let messages = this.state.messages;
    let uid = 0;
    messages.push(new Message({ id: uid, message: data, senderName: "me" }));
    this.setState({ messages });
  };

  render() {
    const { inlineMode, bottomToolbar } = this.props;
    const {
      login,
      loading,
      localAudioEnabled,
      localVideoEnabled,
      screenSharingEnabled,
      collapsed,
      vidFit,
      minimizeMode,
    } = this.state;

    const tools = {
      toggle_microphone: (
        <Tooltip
          title={__("conference.toggle_audio", "Mikrofon ein/ausschalten")}
        >
          <IconButton
            onClick={() => this._handleAudioTrackEnabled(!localAudioEnabled)}
          >
            <Icons.MicrophoneIcon disabled={!localAudioEnabled} />
          </IconButton>
        </Tooltip>
      ),
      toggle_video: (
        <Tooltip title={__("conference.toggle_video", "Video ein/ausschalten")}>
          <IconButton
            size="large"
            type="link"
            onClick={() => this._handleVideoTrackEnabled(!localVideoEnabled)}
          >
            <Icons.VideoIcon disabled={!localVideoEnabled} />
          </IconButton>
        </Tooltip>
      ),
      end_call: (
        <Tooltip title={__("conference.quit", "Videochat beenden")}>
          <Icons.HangupIcon onClick={this._handleLeave} />
        </Tooltip>
      ),
      share_screen: (
        <Tooltip title={__("conference.share_screen", "Bildschirm teilen")}>
          <IconButton
            onClick={() => this._handleScreenSharing(!screenSharingEnabled)}
          >
            <Icon
              component={
                screenSharingEnabled
                  ? Icons.TelevisionOffIcon
                  : Icons.TelevisionIcon
              }
              style={{ display: "flex", justifyContent: "center" }}
            />
          </IconButton>
        </Tooltip>
      ),
      share_url: <ToolShare loginInfo={this.state.loginInfo} />,
    };

    const show_tools = this.state.simpleUserMode
      ? ["toggle_microphone", "end_call", "toggle_video"]
      : Object.keys(tools);

    const toolbar = (
      <div className="app-header-tool">
        {show_tools.map((e, i) => (
          <div key={i}>{tools[e]}</div>
        ))}
      </div>
    );

    let root_class = inlineMode ? "inline-mode" : "normal-mode";
    if (this.state.isFullScreen) {
      root_class += " full-screen";
    }
    if (this.state.minimizeMode) {
      root_class += " minimize-mode";
    } else {
      root_class += " no-minimize-mode";
    }

    const header_logo = "";

    const fullscreen_buttons = (
      <div className="app-fullscreen-layout">
        <Tooltip
          title={__("video.video_stretch_title", "Bild vergrößern/verkleinern")}
        >
          <IconButton size="large" onClick={() => this._onVidFitClickHandler()}>
            {this.state.vidFit ? (
              <Icons.StretchIcon />
            ) : (
              <Icons.UnstretchIcon />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip
          title={__("video.full_screen_title", "Vollbild ein/ausschalten")}
        >
          <Button
            icon={this.state.isFullScreen ? "fullscreen-exit" : "fullscreen"}
            size="large"
            className="app-fullscreen-button"
            onClick={() => this._onFullScreenClickHandler()}
          />
        </Tooltip>
        <Tooltip title={__("video.toggle_pip", "Bild-In-Bild ein/ausschalten")}>
          <IconButton
            size="large"
            onClick={() => this._onMinimizeClickHandler()}
          >
            {this.state.minimizeMode ? (
              <Icons.PiPOffIcon />
            ) : (
              <Icons.PiPOnIcon />
            )}
          </IconButton>
        </Tooltip>
      </div>
    );

    const is_window_visible = !inlineMode || this.state.visible;
    if (!is_window_visible) {
      root_class += " hidden";
    }

    return (
      <Fragment>
        <Draggable
          bounds="body"
          disabled={!minimizeMode}
          //axis="x"
          //handle=".handle"
          defaultPosition={{ x: 0, y: 0 }}
          //position={null}
          //grid={[25, 25]}
          //scale={1}
          //onStart={this.handleStart}
          //onDrag={this.handleDrag}
          //onStop={this.handleStop}
        >
          <div className={root_class}>
            <Layout className="app-layout">
              <Header className="app-header">
                <div className="app-header-left">
                  {/*<a href={home_url} target="_blank">*/}
                  <a>
                    <img src={logo} className="app-logo-img" />
                    {app_config.logo_icon && (
                      <Fragment>
                        <Icons.HeadsetIcon />
                        &nbsp;
                      </Fragment>
                    )}
                    {__("header.logo_text", "Videosprechstunde")}
                  </a>
                </div>
                {login && !bottomToolbar ? toolbar : <div />}
                <div className="app-header-right">
                  {/*}
                {login && (
                  <Tooltip
                    title={__(
                      "conference.toggle_chat",
                      "Öffne/Schließe Chat-Fenster"
                    )}
                  >
                    <Button
                      icon={this.state.collapsed ? "left" : "right"}
                      size="large"
                      type="link"
                      onClick={() => this._openOrCloseLeftContainer(!collapsed)}
                    />
                  </Tooltip>
                )}
                */}
                  <MediaSettings
                    onMediaSettingsChanged={this._onMediaSettingsChanged}
                    settings={this._settings}
                  />
                  {inlineMode && (
                    <Tooltip
                      title={__("videochat.toggle_window", "Schließe Fenster")}
                    >
                      <IconButton
                        onClick={() =>
                          this._openOrCloseWindow(!this.state.visible)
                        }
                      >
                        <Icons.CloseIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Header>

              <Content className="app-center-layout">
                {login ? (
                  <Fragment>
                    <Layout className="app-content-layout">
                      <Layout className="app-right-layout">
                        <Content style={{ flex: 1 }}>
                          <Conference
                            collapsed={this.state.collapsed}
                            client={this.client}
                            settings={this._settings}
                            localAudioEnabled={localAudioEnabled}
                            localVideoEnabled={localVideoEnabled}
                            vidFit={vidFit}
                            minimizeMode={minimizeMode}
                            ref={(ref) => {
                              this.conference = ref;
                            }}
                            userMediaFoundCallback={
                              this._userMediaFoundCallback
                            }
                            chat={
                              <ChatFeed
                                messages={this.state.messages}
                                onSendMessage={this._onSendMessage}
                              />
                            }
                            toolbar={toolbar}
                            side_buttons={fullscreen_buttons}
                          />
                        </Content>
                      </Layout>
                    </Layout>
                  </Fragment>
                ) : loading ? (
                  <div className="loading">
                    <CircularProgress />
                    <div className="loading-message">
                      {__("loading.connecting", "Verbinden ...")}
                    </div>
                  </div>
                ) : (
                  <Card
                    title={__("login.title", "Anmeldung Videochat")}
                    className="app-login-card"
                  >
                    <LoginForm handleLogin={this._handleJoin} />
                  </Card>
                )}
              </Content>
            </Layout>
          </div>
        </Draggable>
        {!is_window_visible && (
          <div className="widget">
            <IconButton
              onClick={() => this._openOrCloseWindow(!this.state.visible)}
            >
              <div className="icon-button">Videosprechstunde</div>
            </IconButton>
          </div>
        )}
      </Fragment>
    );
  }
}

export default App;
